(function($, ko) {
    if (!ko.bindingHandlers.slider) {
        ko.bindingHandlers.slider = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                var options = allBindingsAccessor().sliderOptions || {};
                var values = ko.toJS(valueAccessor());
                $.extend(options, {values: [values.min, values.max]})
                $(element).slider(options);
                $(element).slider({
                    "slide": function (event, ui) {
                        var observable = valueAccessor();
                        observable.min(ui.values[0]);
                        observable.max(ui.values[1]);
                    }
                });
                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    $(element).slider("destroy");
                });
            },
            update: function (element, valueAccessor) {
                var values = ko.toJS(valueAccessor());
                if (!values.min || !values.max) {
                    values = {min: options.min, max: options.max};
                }
                $(element).slider("values", [values.min, values.max]);
            }
        };
    }
})(jQuery, ko);