(function($, ko) {
    if (!ko.bindingHandlers['gmap']) {
        ko.bindingHandlers['gmap'] = {
            init: function (element, valueAccessor, allBindingsAccessor, context) {
                var props = valueAccessor();
                var mapProp = {
                    center:mapaStred,
                    zoom:7,
                    panControl:true,
                    zoomControl:true,
                    mapTypeControl:true,
                    scaleControl:true,
                    streetViewControl:true,
                    overviewMapControl:true,
                    rotateControl:true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                var map = new google.maps.Map(element, mapProp);
                var mapInfoWindow;
                _.each(props, function(prop) {
                    if (!prop.hasOwnProperty("type")) return;
                    if (prop["type"] === "btn") {
                        var btn = document.createElement('div');
                        if (prop.hasOwnProperty("template") && $('#'+prop["template"]).length > 0) {
                            ko.renderTemplate(prop["template"], context, {}, btn);
                            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(btn);
                        }

                        // Setup click-event listener: simply set the map to mapaStred
                        if (prop.hasOwnProperty("action")) {
                            google.maps.event.addDomListener(btn, 'click', function() {
                                mapInfoWindow && mapInfoWindow.close && mapInfoWindow.close();
                                prop.action(map);
                            });
                        }

                    }
                    if (prop["type"] === "locations") {
                        var locations = false;
                        if (prop.hasOwnProperty("locations")) {
                            locations = ko.unwrap(prop.locations);
                        }
                        if (false !== locations && locations.length > 0) {
                            _.each(locations, function(location) {
                                var gps = new google.maps.LatLng(location.gps["lat"], location.gps["lng"]);
                                var marker = new google.maps.Marker({ position: gps, map: map });
                                marker.click = function() {
                                    google.maps.event.trigger(marker, 'click');
                                };
                                location.marker = marker;
                                location.url = "https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination="+location.gps["lat"]+","+location.gps["lng"];
                                google.maps.event.addListener(marker, 'click', function() {
                                    // Render template to dom element
                                    var domInfoWindow = document.createElement('div');
                                    ko.renderTemplate(prop["template"], location, {}, domInfoWindow);
                                    mapInfoWindow && mapInfoWindow.close && mapInfoWindow.close();
                                    mapInfoWindow = new google.maps.InfoWindow({ content: domInfoWindow.firstElementChild });
                                    mapInfoWindow.open(map, marker);
                                    map.setZoom(15);
                                    map.setCenter(marker.getPosition());
                                });
                            });

                        }
                    }
                });
            }
        };
    }
})(jQuery, ko);