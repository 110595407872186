import i18n from 'i18next-ko';
import ko from 'knockout';

(function($, _, ko) {
  if (!ko.bindingHandlers['money_czk']) {
    ko.bindingHandlers['money_czk'] = {
      init :  function(element, valueAccessor, allBindingsAccessor) {
        window.TVP.settings = window.TVP.settings || {};
        let locale = window.TVP.settings.locale || 'cz';

        i18n.init({
          cs: {
            translation: {
              free: 'ZDARMA'
            }
          },
          hu: {
            translation: {
              free: 'INGYENES'
            }
          }
        }, locale, ko);
        return ko.bindingHandlers.text.init(element, valueAccessor, allBindingsAccessor);
      },

      update: function(element, valueAccessor, allBindings, data, context) {
        window.TVP.settings = window.TVP.settings || {};
        let currency = window.TVP.settings.currency || 'CZK';
        let locale = window.TVP.settings.locale || 'cz';
        let symbol = ko.bindingHandlers.money_czk.getCurrencySymbol(locale, currency);

        let options = ko.utils.unwrapObservable(valueAccessor());
        let value = 0;
        if (options !== null && typeof options == "object") {
          value = ko.unwrap(options.value);
        } else {
          value = options;
        }

        let formattedValue = "";
        if(value) {
          let currencyFractionDigits = window.TVP.settings['currency_digits'] || 0;

          let formatter = new Intl.NumberFormat(locale,
            {
              style: 'currency',
              currency: currency,
              minimumFractionDigits: currencyFractionDigits,
              maximumFractionDigits: currencyFractionDigits
            });

          formattedValue = formatter.format(value);
            // formattedValue = String(parseInt(value));
            // if (formattedValue) {
            //     formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + "\xa0"+symbol;
            // }
        } else if (value === 0) {
            formattedValue = ko.unwrap(i18n.t('free', 'ZDARMA'));
        }
        ko.bindingHandlers.text.update(element, function() { return formattedValue; }, allBindings, data, context);
      },
      getCurrencySymbol: function(locale, currency) {
        return (0).toLocaleString(locale,{
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).replace(/\d/g, '').trim()
      }
    };
    ko.expressionRewriting.bindingRewriteValidators['flickity'] = false;
  }
})(jQuery, _, ko);