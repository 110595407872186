(function($, ko) {
    if (!ko.bindingHandlers['bool']) {
        ko.bindingHandlers['bool'] = {
            'init' :  ko.bindingHandlers.text.init,
            'update': function(element, valueAccessor, allBindings, data, context) {
                var value = ko.utils.unwrapObservable(valueAccessor());

                var formattedValue = "Ne";
                if(value) {
                    formattedValue = "Ano";
                }
                ko.bindingHandlers.text.update(element, function() { return formattedValue; }, allBindings, data, context);
            }
        };
    }
})(jQuery, ko);