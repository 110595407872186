import phone from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils.js'

(function() {
  if (!ko.bindingHandlers['phone']) {
    ko.bindingHandlers['phone'] = {
      init: function(element, valueAccessor, allBindingsAccessor) {
        let value = valueAccessor();
        let allBindings = allBindingsAccessor();

        // Настройка компонента по умолчанию
        ko.bindingHandlers.phone.options = {
          separateDialCode: true,
          excludeCountries: ["us"],
          localizedCountries: { 'cz': 'Czech Republic' },
        };

        let options = ko.bindingHandlers.phone.options;

        if (allBindings.phoneOptions) {
          ko.utils.extend(options, allBindings.phoneOptions);
        }
        if (value.options) {
          ko.utils.extend(options, value.options);
        }

        value.extend( {options: options} );

        // Для Венгрии меняется отображаемая подсказка (placeholder)
        options.customPlaceholder = function(
          selectedCountryPlaceholder,
          selectedCountryData)
        {
          if (selectedCountryData.iso2 === 'hu') {
            return selectedCountryPlaceholder.replace(/^06/gi, '');
          }
          return selectedCountryPlaceholder;
        }

        const iti = phone(element, options);
        iti.setNumber(value());

        // При переходе на следующее поле устанавливает новое значение в поле
        element.addEventListener('blur', function() {
          value(iti.getNumber());
          iti.setNumber(value());
        });

        // Для интеграции компонента с модулем проверки формы необходимо для
        // правила проверки 'intl-tel-input' передать экземпляр компонента в
        // качестве параметра params.component.
        let rules = ko.unwrap(allBindings.validationElement.rules ?? []);
        let rule = _.find(rules, { rule: 'intlTelInput'});
        if (rule) {
          rule.params = rule.params ?? {};
          rule.params.component = iti;
        }

        // Необходимо инициировать проверку значение после загрузки
        // компонента для правильного отображение результата проверки.
        if (value().length > 0) {
          value.valueHasMutated();
        }
      },
    };
  }

  if (!ko.extenders.phoneOptions) {
    ko.extenders.phoneOptions = function(target, options) {
      let opts = target.options ?? {};
      ko.utils.extend(opts, options);
      target.extend({options: options});
    }
  }
})();
