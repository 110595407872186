import ko from 'knockout'

(function($, ko) {
    if (!ko.bindingHandlers['birthday']) {
        ko.bindingHandlers['birthday'] = {
            'init' :  function (element, valueAccessor, allBindingsAccessor) {
                var value = valueAccessor(), allBindings = allBindingsAccessor();
                var options = ko.bindingHandlers.birthday.options;
                ko.utils.extend(options, allBindings.birthdayOptions);
                if (options.hasOwnProperty("day")) options["required-day"] = $(options["day"]);
                if (options.hasOwnProperty("month")) options["required-month"] = $(options["month"]);
                if (options.hasOwnProperty("year")) options["required-year"] = $(options["year"]);
                value.extend({ element: element, options: options });
                value.errorElement = ko.observable(null);

                // If validation is use
                if (value.isValid && ko.isObservable(value.isValid)) {
                    value.isValid.subscribe(function(valid) {
                        var self = this, valid = value.isValid(), elements = [];
                        //console.log("isValid: isValid="+JSON.stringify(valid)+"; isValid()="+JSON.stringify(value.isValid())+"; isModified()="+JSON.stringify(value.isModified())+";");
                        $.each([options["required-day"], options["required-month"], options["required-year"]], function(i, element) {
                            element.prop("title", "");
                            if (valid && self.isModified() && !element.hasClass("has-success")) {
                                //console.log("Add class "+i+": has-success");
                                element.addClass("has-success");
                            } else {
                                //console.log("Remove class"+i+": has-success");
                                element.removeClass("has-success");
                            }
                            element.removeClass("is-invalid");
                        });

                        if (!self.isModified()) return;
                        var rule = ko.utils.arrayFirst(self.rules(), function(rule) { return rule["message"] === self.error()});
                        if (rule && rule.hasOwnProperty("rule") && options.hasOwnProperty(rule.rule)) {
                            elements = [options[rule.rule]];
                        } else {
                            elements = [options["required-day"], options["required-month"], options["required-year"]];
                        }
                        if (!elements.length) return;
                        $.each(elements, function(i, element) {
                            element.prop("title", self.error());
                            element.toggleClass("has-success", valid);
                            element.toggleClass("is-invalid", !valid);
                        });
                        value.errorElement(elements.length === 1 ? elements[0] : elements[1]);
                        self.isModified(false);
                    }, value);
                }
                if (value.isModified && ko.isObservable(value.isModified)) {
                    value.isModified.subscribe(function (modified) {
                        //console.log("isModified: isModified="+JSON.stringify(modified)+"; isModified()="+JSON.stringify(value.isModified())+"; isValid()="+JSON.stringify(value.isValid())+";");
                        if (modified) { value.isValid.notifySubscribers(value.isValid()); }
                    }, value);
                }
                if (options.hasOwnProperty("day") && options["day"]) {
                    $(options["day"]).change(function() { value(value().replace(/(\d{4})-(\d{1,2})-(\d{1,2})/g, '$1-$2-' + ("00"+$(this).val()).slice(-2))); });
                }
                if (options.hasOwnProperty("month") && options["month"]) {
                    $(options["month"]).change(function() { value(value().replace(/(\d{4})-(\d{1,2})-(\d{1,2})/g, '$1-'+("00"+$(this).val()).slice(-2)+'-$3')); });
                }
                if (options.hasOwnProperty("year") && options["year"]) {
                    $(options["year"]).change(function() { value(value().replace(/(\d{4})-(\d{1,2})-(\d{1,2})/g, ("0000"+$(this).val()).slice(-4)+'-$2-$3')); });
                }
            },
            'update': function(element, valueAccessor) {
                //ko.bindingHandlers.value.update(element, valueAccessor);
                var value = ko.utils.unwrapObservable(valueAccessor());
                var options = valueAccessor().options;
                var re = /(\d{4})-(\d{1,2})-(\d{1,2})/g;
                var result = re.exec(value);
                if (options.hasOwnProperty("day") && options["day"] && result[3]) {
                    $(options["day"]).val(parseInt(result[3]) ? parseInt(result[3]) : null);
                }
                if (options.hasOwnProperty("month") && options["month"] && result[2]) {
                    $(options["month"]).val(parseInt(result[2]) ? parseInt(result[2]) : null);
                }
                if (options.hasOwnProperty("year") && options["year"]) {
                    $(options["year"]).val(parseInt(result[1]) ? parseInt(result[1]) : null);
                }
            },
            'options': {}
        };
    }
})(jQuery, ko);