(function($, ko) {
    if (!ko.bindingHandlers.toggleClick) {
        ko.bindingHandlers.toggleClick = {
            init: function(element, valueAccessor) {
                let value = valueAccessor();

                ko.utils.registerEventHandler(element, "click", function(event) {
                    value(!value());
                    event.cancelBubble = true;
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                });
            }
        };
    }
})(jQuery, ko);