import 'jquery-ui'

(function($, ko) {
    if (!ko.bindingHandlers['tooltip']) {
        ko.bindingHandlers['tooltip'] = {
            'init' :  function(element, valueAccessor) {
                var local = ko.utils.unwrapObservable(valueAccessor()),
                    options = {};

                ko.utils.extend(options, ko.bindingHandlers.tooltip.options);
                ko.utils.extend(options, local);

                $(element).tooltip(options);

                ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                    $(element).tooltip("dispose");
                });
            },
            'options': {
                animation: true,
                html: true,
                delay: {"show": 500, "hide": 100},
                placement: "bottom",
                trigger: "hover"
            }
        };
    }
})(jQuery, ko);