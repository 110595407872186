(function($, ko) {
    if (!ko.bindingHandlers['slideVisible']) {
        ko.bindingHandlers['slideVisible'] = {
            'init': function (element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                $(element).toggle(value);
            },
            'update': function (element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                value ? $(element).slideDown() : $(element).slideUp();
            }
        };
    }
})(jQuery, ko);