import jQuery from 'jquery'
import Flickity from 'flickity'
import jQueryBridget from 'jquery-bridget'

(function($, _, ko) {
    if (!ko.bindingHandlers['flickity']) {
        ko.bindingHandlers['flickity'] = {
            makeTemplateValueAccessor: function(element, valueAccessor) {
                return function() {
                    let modelValue = valueAccessor();
                    return {
                        'foreach': modelValue,
                        'afterRender': $.proxy(ko.bindingHandlers['flickity'].afterRender, null, element, valueAccessor),
                        'templateEngine': ko.nativeTemplateEngine.instance
                    };
                };
            },
            'afterRender': function(container, valueAccessor, elements, model) {
                let $container = $(container);
                // Exclude empty content
                elements = elements.filter(function(element) {
                    return element.nodeType !== 3;
                });
                if (elements.length <= 0) return;

                let modelValues = valueAccessor();
                // last element
                if (_.isMatch(model, _.last(modelValues))) {
                    // Настройки компонента, которые задаются в атрибуте data-flickity-options
                    let options = $container.attr('data-flickity-options');
                    if (typeof options !== typeof undefined && options !== false) {
                        options = JSON.parse(options);
                    } else {
                        options = {};
                    }

                    $container.on( 'ready.flickity', _.debounce(function() {
                        $container.flickity("resize");
                    }, 260));
                    $container.flickity(options);
                    // _.debounce(function() {
                    //     $container.flickity(options);
                    // }, 100)();
                }
            },
            'init': function(element, valueAccessor) {
                Flickity.setJQuery( $ );
                jQueryBridget('flickity', Flickity, $);

                return ko.bindingHandlers['foreach']['init'](element, valueAccessor);
            },
            'update': function(element, valueAccessor, allBindings, viewModel, bindingContext) {
                return ko.bindingHandlers['template']['update'](
                    element,
                    ko.bindingHandlers['flickity'].makeTemplateValueAccessor(element, valueAccessor),
                    allBindings,
                    viewModel,
                    bindingContext
                );
            }
        };
        ko.expressionRewriting.bindingRewriteValidators['flickity'] = false;
    }
})(jQuery, _, ko);
