(function($, ko) {
  if (!ko.bindingHandlers['placeholder']) {
    ko.bindingHandlers['placeholder'] = (function() {
      var element = null,
          // Список текстов для отображения
          texts = [],

          // Индекс отображаемого текста
          index = ko.observable(0),

          // Длинна отображаемого текста
          length = ko.observable(0),

          // 0 - left to right
          // 1 - right to left
          direction = 0,
          hasFocus = ko.observable(false),
          interval = 150,
          intervalId = null;

      init = function (el, valueAccessor, allBindingsAccessor) {
        let value = valueAccessor(), allBindings = allBindingsAccessor();
        if (value.length === 0) return;

        texts = value;
        element = el;
        if (allBindings.hasOwnProperty("placeholderInterval")
          && Number.isInteger(allBindings["placeholderInterval"])
        ) {
          interval = allBindings["placeholderInterval"];
        }

        hasFocus.subscribe(focusChanged);
        index.subscribe(indexChanged);
        length.subscribe(lengthChanged);

        $(el).focus(() => { console.log('hasFocus <= true'); hasFocus(true);  });
        $(el).blur(() => { console.log('hasFocus <= false'); hasFocus(false);  });
        focusChanged(false);
      },

      focusChanged = function(focus) {
        if ($(element).val().length === 0 && !intervalId && !focus) {
          intervalId = setInterval(() => { ping() }, interval);
        } else if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
          length(0);
        }
        console.log('hasFocus => '+focus);
      },

      indexChanged = function(curIndex) {
        if (curIndex >= texts.length) {
          index(0);
        }
      },

      lengthChanged = function(curLength) {
        let curIndex = ko.unwrap(index);

        let text = texts[curIndex];
        let textLength = text.length;

        if (curLength > textLength) {
          direction = 1;
        } else if (curLength < 0) {
          direction = 0;
          index(curIndex + 1)
        } else {
          text = text.substring(0, curLength);
          $(element).attr("placeholder", text);
        }
      },

      ping = function ($element, valueAccessor) {
        let prevLength = ko.unwrap(length);

        if (0 === ko.unwrap(direction)) {
          length(prevLength + 1);
        } else {
          length(prevLength - 1);
        }
      };

      return {
        init: init,
      };
    }());
  }
})(jQuery, ko);