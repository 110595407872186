(function($, ko) {
    if (!ko.bindingHandlers['position']) {
        ko.bindingHandlers['position'] = {
            init: function (element, valueAccessor) {
                let getPosition = function($element) {
                    var offset = $element.offset();
                    let position = {};
                    position.top = offset.top;
                    position.left = offset.left;
                    position.width = $element.width();
                    position.height = $element.height();
                    return position;
                }

                let $element = $(element);
                let value = valueAccessor();
                value(getPosition($element));
                $element.on('shown.bs.collapse', () => {
                    value(getPosition($element));
                })
                $element.on('hidden.bs.collapse', () => {
                    value(getPosition($element));
                })
            }
        };
    }
})(jQuery, ko);