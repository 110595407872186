(function($, ko) {
    if (!ko.bindingHandlers['mask']) {
        ko.bindingHandlers['mask'] = {
            'init' :  function (element, valueAccessor, allBindingsAccessor) {
                var options = allBindingsAccessor().maskOptions || {};
                if (!options.hasOwnProperty("mask")) return;

                var mask = options.mask;
                delete options["mask"];
                $(element).mask(mask, options);

                var modifyPrefix = function(prefix, element, destroy) {
                    destroy = typeof destroy !== 'undefined' ? destroy : false;
                    if (prefix.length <= 0) return;
                    var elem = $(element);
                    var value = elem.val();

                    var propablePrefix = prefix.substr(0, Math.min(prefix.length, value.length));
                    if (value.indexOf(propablePrefix) < 0) {
                        value = value.replace("+", "");
                        value = options["prefix"] + value;
                    } else if (value.indexOf(propablePrefix) === 0 && propablePrefix.length !== prefix.length) {
                        if (propablePrefix.length > 0)
                            value = value.replace(propablePrefix, '');
                        value = options["prefix"] + value;
                    }
                    if (destroy && value === prefix) {
                        value = "";
                    }
                    $(element).val(value);
                    if (elem.setSelectionRange && !destroy) {
                        elem.setSelectionRange(value.length, value.length);
                    }

                };
                ko.utils.registerEventHandler(element, 'focusin', function () {
                    if (options.hasOwnProperty("prefix")) {
                        modifyPrefix(options["prefix"], element);
                    }
                });
                ko.utils.registerEventHandler(element, 'focusout', function () {
                    var doNotUpdate = true;
                    if (options.hasOwnProperty("prefix")) {
                        modifyPrefix(options["prefix"], element, true);
                    }
                    if (doNotUpdate) {
                        var observable = valueAccessor();
                        observable($(element).val());
                    }
                });
                ko.utils.registerEventHandler(element, 'keyup', function () {
                    if (options.hasOwnProperty("prefix")) {
                        modifyPrefix(options["prefix"], element);
                    }
                });
                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    $(element).unmask();
                });
            },
            'update': function (element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                $(element).val(value).trigger('keyup');
            }
        };
    }

})(jQuery, ko);