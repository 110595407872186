(function($, ko) {
    if (!ko.bindingHandlers['distance']) {
        ko.bindingHandlers['distance'] = {
            'update': function(element, valueAccessor, allBindingsAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor()),
                    precision = ko.utils.unwrapObservable(allBindingsAccessor().precision) || ko.bindingHandlers.distance.defaultPrecision;

                var formattedValue = value.toFixed(precision) + "\xa0km";
                ko.bindingHandlers.text.update(element, function() { return formattedValue; });
            },
            defaultPrecision: 1
        };
    }
})(jQuery, ko);