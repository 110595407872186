(function($, ko) {
    if (!ko.bindingHandlers['modal']) {
        ko.bindingHandlers['modal'] = {
            init: function (element, valueAccessor) {
                $(element).on('resize.bs.modal', (e) => {
                    let modalObj = $(this).find('.modal-content');
                    if ($(modalObj).height() > ($(window).height()*0.8)) {
                        $(modalObj).height($(window).height()*0.8);
                    }
                });
                $(element).on('hide.bs.modal', (e) => {
                    $("body").removeClass("modal-open");
                });
                $(element).on('hidden.bs.modal', (e) => {
                    valueAccessor()(false);
                });
            },
            update: function (element, valueAccessor) {
                let value = valueAccessor();
                if (ko.unwrap(value)) {
                    $(element).modal('show');
                } else {
                    $(element).modal('hide');
                }
            }
        };
    }
})(jQuery, ko);